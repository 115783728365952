import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { INikkeCharacterNode } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import Switch from 'react-switch';
import lodash from 'lodash';

import './nikke-character-dynamic.scss';
import { Row, Col, OverlayTrigger, Popover, Accordion } from 'react-bootstrap';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCircleInfo,
  faDiagramProject,
  faSquare
} from '@fortawesome/free-solid-svg-icons';
import { Seo } from '../modules/common/components/seo';
import { NikkeSpecialitiesArray } from '../modules/nikke/character/model/nikke-specialities-array';
import { RatingBox } from '../modules/common/components/rating-box';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { CategoryCard } from '../modules/cs/main/components/category-card';
import { NikkeCubeTable } from '../modules/nikke/common/components/nikke-cube-table';
import { NikkeSkill } from '../modules/nikke/common/components/nikke-skill';
import { NIKKECharacterRatings } from '../modules/nikke/data/character-ratings';
import { NikkeCharacter } from '../modules/nikke/common/components/nikke-character';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

const SpecialityItem = ({ speciality }) => {
  const specialityObj = lodash.find(NikkeSpecialitiesArray, {
    value: speciality
  });
  if (specialityObj) {
    const popoverSpecialities = (
      <Popover id="popover-specialities">
        <Popover.Header as="h3">{specialityObj.label}</Popover.Header>
        <Popover.Body>{specialityObj.description}</Popover.Body>
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="auto-start"
        overlay={popoverSpecialities}
      >
        <div className="employee-role">{specialityObj.label}</div>
      </OverlayTrigger>
    );
  } else {
    return <span>{speciality}</span>;
  }
};

interface INikkeCharacterNodes {
  nodes: INikkeCharacterNode[];
}

interface INikkeCharacterEntry {
  currentUnit: INikkeCharacterNodes;
}

interface IProps {
  data: INikkeCharacterEntry;
}

const NikkeCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newRankings, setNewRankings] = useState(
    NIKKECharacterRatings.find((rankingCharacter) => {
      if (rankingCharacter.Slug === character.slug) return true;
    })
  );
  const [showMaxedSkills, setShowMaxedSkills] = useState(true);
  const [currentTab, setCurrentTab] = useState('Info');

  const fullImage = character.fullImage
    ? getImage(character.fullImage.localFile.childImageSharp)
    : null;

  const profileImage = character.cardImage
    ? getImage(character.cardImage.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-nikke-v2'}
      game="nikke"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>
              <span className="sub">Information and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background rarity-${character.rarity} ${character.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section ">
          <Col xs={12} xl={12}>
            <div className={`content-header rarity-${character.rarity}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong>{character.name}</strong> is a{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.rarity} rarity
                  </strong>{' '}
                  {character.slug === 'red-hood' ? (
                    <>
                      <strong>Burst</strong>{' '}
                      <StaticImage
                        src="../images/nikke/icons/type_all_bg.png"
                        alt="Type All"
                      />
                    </>
                  ) : (
                    <>
                      {character.burstType === '1' && (
                        <>
                          <strong>Burst</strong>{' '}
                          <StaticImage
                            src="../images/nikke/icons/type_1_bg.png"
                            alt={character.burstType}
                          />
                        </>
                      )}
                      {character.burstType === '2' && (
                        <>
                          <strong>Burst</strong>{' '}
                          <StaticImage
                            src="../images/nikke/icons/type_2_bg.png"
                            alt={character.burstType}
                          />
                        </>
                      )}
                      {character.burstType === '3' && (
                        <>
                          <strong>Burst</strong>{' '}
                          <StaticImage
                            src="../images/nikke/icons/type_3_bg.png"
                            alt={character.burstType}
                          />
                        </>
                      )}
                    </>
                  )}{' '}
                  character from the <strong>{character.class}</strong> class,
                  who wields a <strong>{character.weapon}</strong>{' '}
                  {character.weapon === 'Assault Rifle' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/weapon_ar.png"
                        alt={character.weapon}
                      />
                    </>
                  )}
                  {character.weapon === 'Minigun' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/weapon_minigun.png"
                        alt={character.weapon}
                      />
                    </>
                  )}
                  {character.weapon === 'Rocket Launcher' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/weapon_rocket.png"
                        alt={character.weapon}
                      />
                    </>
                  )}
                  {character.weapon === 'Shotgun' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/weapon_shotgun.png"
                        alt={character.weapon}
                      />
                    </>
                  )}
                  {character.weapon === 'SMG' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/weapon_smg.png"
                        alt={character.weapon}
                      />
                    </>
                  )}
                  {character.weapon === 'Sniper Rifle' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/weapon_sniper.png"
                        alt={character.weapon}
                      />
                    </>
                  )}
                  {character.weapon === 'Unknown' && (
                    <>
                      <span>{character.weapon}</span>
                    </>
                  )}{' '}
                  weapon and belongs to the <strong>{character.element}</strong>{' '}
                  <>
                    {character.element === 'Wind' && (
                      <StaticImage
                        src="../images/nikke/icons/wind_bg.png"
                        alt="Wind"
                      />
                    )}
                    {character.element === 'Water' && (
                      <StaticImage
                        src="../images/nikke/icons/water_bg.png"
                        alt="Water"
                      />
                    )}
                    {character.element === 'Electric' && (
                      <StaticImage
                        src="../images/nikke/icons/ele_bg.png"
                        alt="Electric"
                      />
                    )}
                    {character.element === 'Iron' && (
                      <StaticImage
                        src="../images/nikke/icons/iron_bg.png"
                        alt="Iron"
                      />
                    )}
                    {character.element === 'Fire' && (
                      <StaticImage
                        src="../images/nikke/icons/fire_bg.png"
                        alt="Fire"
                      />
                    )}
                  </>{' '}
                  element. She's part of the{' '}
                  <strong>{character.manufacturer}</strong>{' '}
                  {character.manufacturer === 'Elysion' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/man_elysion.png"
                        alt="Elysion"
                      />
                    </>
                  )}
                  {character.manufacturer === 'Tetra' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/man_tetra.png"
                        alt="Tetra"
                      />
                    </>
                  )}
                  {character.manufacturer === 'Missilis' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/man_missilis.png"
                        alt="Missilis"
                      />
                    </>
                  )}
                  {character.manufacturer === 'Pilgrim' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/man_pilgrim.png"
                        alt="Pilgrim"
                      />
                    </>
                  )}
                  {character.manufacturer === 'Abnormal' && (
                    <>
                      <StaticImage
                        src="../images/nikke/icons/man_abnormal.png"
                        alt="Abnormal"
                      />
                    </>
                  )}{' '}
                  faction.{' '}
                  {character.hasTreasure && (
                    <>
                      This character information showcase the{' '}
                      <strong>Treasure</strong> version only.
                    </>
                  )}
                </h2>
                <div className="char-intro">
                  {character.backstory ? (
                    <>{character.backstory.backstory}</>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
        </Row>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`rarity-${character.rarity}`}>
            {character.name}
          </strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Profile</p>
          </div>
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab rarity-${character.rarity} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
            </div>
            <p>Investment tips</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div
            className={`content-header with-switch rarity-${character.rarity}`}
          >
            <div>
              <FontAwesomeIcon icon={faSquare} width="18" /> Skills
            </div>
            <div className="custom-switch">
              <Switch
                checked={showMaxedSkills}
                onChange={() => setShowMaxedSkills(!showMaxedSkills)}
                onColor="#009EEC"
                offColor="#484950"
                className="switch"
              />
              Max level
            </div>
          </div>
          {character.skills && character.hideSkills !== true ? (
            <>
              <div className={`skills rarity-${character.rarity}`}>
                <Row xs={1} xxl={2}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div
                          className={`skill-icon  rarity-${character.rarity}`}
                        >
                          Normal Attack
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Normal Attack</p>
                          <p className="skill-type">{character.weapon}</p>
                        </div>
                      </div>
                      <div className={`additional-information ultimate`}>
                        <p>
                          Mode: <span>{character.controlMode}</span>
                        </p>
                        <p>
                          Ammo: <span>{character.ammoCapacity}</span>
                        </p>
                        <p>
                          Reload: <span>{character.reloadTime}s</span>
                        </p>
                      </div>
                      <div
                        className={`skill-description rarity-${character.rarity}`}
                      >
                        <>{renderRichText(character.basicAttack, options)}</>
                      </div>
                    </div>
                  </Col>
                  {character.skills.map((skill, index) => {
                    return (
                      <Col key={index}>
                        <div className="box">
                          <div className="skill-header">
                            <div
                              className={`skill-icon rarity-${character.rarity}`}
                            >
                              {skill.slot}
                            </div>
                            <div className="skill-info">
                              <p className="skill-name">{skill.name}</p>
                              <p className="skill-type">{skill.type}</p>
                            </div>
                          </div>
                          <div className={`additional-information`}>
                            {skill.type === 'Active' ? (
                              <p>
                                Cooldown: <span>{skill.cooldown}s</span>
                              </p>
                            ) : (
                              <p>
                                Cooldown: <span>-</span>
                              </p>
                            )}
                            {skill.phase && (
                              <p>
                                Unlocks at: <span>{skill.phase}</span>
                              </p>
                            )}
                          </div>
                          <div
                            className={`skill-description rarity-${character.rarity}`}
                          >
                            {showMaxedSkills ? (
                              <>
                                {renderRichText(
                                  skill.descriptionLevel10,
                                  options
                                )}
                              </>
                            ) : (
                              <>
                                {renderRichText(
                                  skill.descriptionLevel1,
                                  options
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>skills aren't available yet.</strong> They will be
                  added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Specialties
          </div>
          <div className="specialities-list">
            {character.specialities ? (
              character.specialities.sort().map((speciality, idx) => {
                return <SpecialityItem key={idx} speciality={speciality} />;
              })
            ) : (
              <span className="no-spec">-</span>
            )}
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Voice Actors
          </div>
          <Row xs={1} xxl={3} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category">ENG</div>
                <div className="details">
                  {character.cv.en ? character.cv.en : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">JPN</div>
                <div className="details">
                  {character.cv.jpn ? character.cv.jpn : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">KR</div>
                <div className="details">
                  {character.cv.kr ? character.cv.kr : '-'}
                </div>
              </div>
            </Col>
          </Row>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gallery
          </div>
          {fullImage ? (
            <GatsbyImage image={fullImage} alt="" className="full-image" />
          ) : (
            <div className="info-box">
              <p>
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>images aren't available yet.</strong> They will be added
                when the character is released.
              </p>
            </div>
          )}
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Review
          </div>
          {!character.isReviewPending ? (
            <div className="section-analysis">
              {character.slug === 'crown' ||
              character.slug === 'grave' ||
              character.slug === 'rumani' ||
              character.slug === 'rosanna-chic-ocean' ||
              character.slug === 'red-hood' ||
              character.slug === 'ein' ||
              character.slug === 'd-killer-wife' ||
              character.slug === 'emilia' ||
              character.slug === 'rem' ||
              character.slug === 'flora' ||
              character.slug === 'rapi-red-hood' ||
              character.slug === 'elegg' ||
              character.slug === 'sakura-bloom-in-summer' ||
              character.slug === 'kilo' ||
              character.slug === 'ade' ||
              character.slug === 'zwei' ||
              character.slug === 'guillotine-winter-slayer' ||
              character.slug === 'quency-escape-queen' ||
              character.slug === 'bay' ||
              character.slug === 'trony' ||
              character.slug === 'soda-twinkling-bunny' ||
              character.slug === 'privaty-unkind-maid' ||
              character.slug === 'cinderella' ||
              character.slug === 'asuka-shikinami-langley' ||
              character.slug === 'asuka-shikinami-langley-wille' ||
              character.slug === 'rei-ayanami' ||
              character.slug === 'rei-ayanami-tentative-name' ||
              character.slug === 'mari-makinami-illustrious' ||
              character.slug === 'helm-treasure' ||
              character.slug === 'alice-wonderland-bunny' ||
              character.slug === 'tia' ||
              character.slug === 'maiden-ice-rose' ||
              character.slug === 'phantom' ||
              character.slug === 'rouge' ||
              character.slug === 'mast-romantic-maid' ||
              character.slug === 'anchor-innocent-maid' ||
              character.slug === 'mana' ||
              character.slug === 'milk-treasure' ||
              character.slug === 'miranda-treasure' ||
              character.slug === 'trina' ||
              character.slug === 'clay' ||
              character.slug === 'bready' ||
              character.slug === 'naga' ? (
                <>
                  <div className="info-box">
                    <p>
                      <strong className={`rarity-${character.rarity}`}>
                        {character.name}
                      </strong>{' '}
                      <strong>has a dedicated review/guide available.</strong>{' '}
                      You can find it by following the link below.
                    </p>
                  </div>
                  <Row
                    xs={1}
                    md={2}
                    lg={2}
                    xxl={2}
                    className="g-3 category-cards-container"
                  >
                    {character.slug === 'bready' && (
                      <CategoryCard
                        title="Bready"
                        link="/nikke/guides/guide-bready"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_bready.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'milk-treasure' && (
                      <CategoryCard
                        title="Milk (Treasure)"
                        link="/nikke/guides/guide-milk-treasure"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_milk.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'miranda-treasure' && (
                      <CategoryCard
                        title="Miranda (Treasure)"
                        link="/nikke/guides/guide-miranda-treasure"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_miranda.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'trina' && (
                      <CategoryCard
                        title="Trina"
                        link="/nikke/guides/guide-trina"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_trina.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'rei-ayanami-tentative-name' && (
                      <CategoryCard
                        title="Rei Ayanami: Tentative Name"
                        link="/nikke/guides/guide-rei"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_rei2.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'asuka-shikinami-langley-wille' && (
                      <CategoryCard
                        title="Asuka Shikinami Langley: Wille"
                        link="/nikke/guides/guide-asuka-wille"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_wille.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'helm-treasure' && (
                      <CategoryCard
                        title="Helm (Treasure)"
                        link="/nikke/guides/guide-helm-treasure"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_helm.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'anchor-innocent-maid' && (
                      <CategoryCard
                        title="Anchor: Innocent Maid"
                        link="/nikke/guides/guide-anchor-maid"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_anchor.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'mast-romantic-maid' && (
                      <CategoryCard
                        title="Mast: Romantic Maid"
                        link="/nikke/guides/guide-mast-maid"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_mast.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'mana' && (
                      <CategoryCard
                        title="Mana"
                        link="/nikke/guides/guide-mana"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_mana.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'rapi-red-hood' && (
                      <CategoryCard
                        title="Rapi: Red Hood"
                        link="/nikke/guides/guide-rapi-red-hood"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_rapi.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'guillotine-winter-slayer' && (
                      <CategoryCard
                        title="Guillotine: Winter Slayer"
                        link="/nikke/guides/guide-guillotine-winter-slayer"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_xguilo.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'maiden-ice-rose' && (
                      <CategoryCard
                        title="Maiden: Ice Rose review"
                        link="/nikke/guides/guide-maiden-ice-rose"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_xmaiden.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'flora' && (
                      <CategoryCard
                        title="Flora review"
                        link="/nikke/guides/guide-flora"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_flora.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'grave' && (
                      <CategoryCard
                        title="Grave review"
                        link="/nikke/guides/guide-grave"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_grave.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'cinderella' && (
                      <CategoryCard
                        title="Cinderella review"
                        link="/nikke/guides/guide-cinderella"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_cinderella.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'rumani' && (
                      <CategoryCard
                        title="Rumani review"
                        link="/nikke/guides/guide-rumani"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_rumani.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'phantom' && (
                      <CategoryCard
                        title="Phantom review"
                        link="/nikke/guides/guide-phantom"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_phantom.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'quency-escape-queen' && (
                      <CategoryCard
                        title="Quency: Escape Queen review"
                        link="/nikke/guides/guide-quency-escape-queen"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_quency_escape.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'rouge' && (
                      <CategoryCard
                        title="Rouge review"
                        link="/nikke/guides/guide-rouge"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_rouge.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'mari-makinami-illustrious' && (
                      <CategoryCard
                        title="Mari Makinami Illustrious review"
                        link="/nikke/guides/guide-mari-makinami"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_mari.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'rei-ayanami' && (
                      <CategoryCard
                        title="Rei Ayanami review"
                        link="/nikke/guides/guide-rei-ayanami"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_rei.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'asuka-shikinami-langley' && (
                      <CategoryCard
                        title="Asuka Shikinami Langley review"
                        link="/nikke/guides/guide-asuka"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_asuka.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'zwei' && (
                      <CategoryCard
                        title="Zwei review"
                        link="/nikke/guides/guide-zwei"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_zwei.webp"
                            alt="Zwei"
                          />
                        }
                      />
                    )}
                    {character.slug === 'ein' && (
                      <CategoryCard
                        title="Ein review"
                        link="/nikke/guides/guide-ein"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_ein.webp"
                            alt="Guide"
                          />
                        }
                      />
                    )}
                    {character.slug === 'rosanna-chic-ocean' && (
                      <CategoryCard
                        title="Rosanna Summer review"
                        link="/nikke/guides/guide-rosanna-summer"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_rosanna_chic.webp"
                            alt="RosannaSummer"
                          />
                        }
                      />
                    )}
                    {character.slug === 'sakura-bloom-in-summer' && (
                      <CategoryCard
                        title="Sakura Summer review"
                        link="/nikke/guides/guide-sakura-summer"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_sakurasummer.webp"
                            alt="Sakura Summer"
                          />
                        }
                      />
                    )}
                    {character.slug === 'clay' && (
                      <CategoryCard
                        title="Clay review"
                        link="/nikke/guides/guide-clay"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_clay.webp"
                            alt="Clay"
                          />
                        }
                      />
                    )}
                    {character.slug === 'alice-wonderland-bunny' && (
                      <CategoryCard
                        title="Alice: Wonderland Bunny review"
                        link="/nikke/guides/guide-alice-bunny"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_alicebunny.webp"
                            alt="Alice: Wonderland Bunny"
                          />
                        }
                      />
                    )}
                    {character.slug === 'soda-twinkling-bunny' && (
                      <CategoryCard
                        title="Soda: Twinkling Bunny review"
                        link="/nikke/guides/guide-soda-bunny"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_sodabunny.webp"
                            alt="Soda Bunny review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'trony' && (
                      <CategoryCard
                        title="Trony review"
                        link="/nikke/guides/guide-trony"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_trony.webp"
                            alt="Trony review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'kilo' && (
                      <CategoryCard
                        title="Kilo review"
                        link="/nikke/guides/guide-kilo"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_kilo.webp"
                            alt="Kilo review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'bay' && (
                      <CategoryCard
                        title="Bay review"
                        link="/nikke/guides/guide-bay"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_bay.webp"
                            alt="Bay review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'crown' && (
                      <CategoryCard
                        title="Crown review"
                        link="/nikke/guides/guide-crown"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_crown.webp"
                            alt="Crown review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'rem' && (
                      <CategoryCard
                        title="Rem review"
                        link="/nikke/guides/guide-rem"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_rem.webp"
                            alt="Rem review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'naga' && (
                      <CategoryCard
                        title="Naga dedicated guide"
                        link="/nikke/guides/naga-guide"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_naga.jpg"
                            alt="Naga review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'tia' && (
                      <CategoryCard
                        title="Tia dedicated guide"
                        link="/nikke/guides/tia-guide"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_tia.jpg"
                            alt="Tia review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'red-hood' && (
                      <CategoryCard
                        title="Red Hood dedicated guide"
                        link="/nikke/guides/red-hood-guide"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_red.jpg"
                            alt="Red Hood review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'privaty-unkind-maid' && (
                      <CategoryCard
                        title="Privaty: Unkind Maid review"
                        link="/nikke/guides/guide-privaty-maid"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_priv.jpg"
                            alt="Privaty: Unkind Maid review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'ade' && (
                      <CategoryCard
                        title="Ade review"
                        link="/nikke/guides/guide-ade"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_ade.jpg"
                            alt="Ade review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'elegg' && (
                      <CategoryCard
                        title="Elegg review"
                        link="/nikke/guides/guide-elegg"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_elegg.jpg"
                            alt="Elegg review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'd-killer-wife' && (
                      <CategoryCard
                        title="D: Killer Wife review"
                        link="/nikke/guides/guide-d-killer-wife"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_dkiller.jpg"
                            alt="D: Killer Wife review"
                          />
                        }
                      />
                    )}
                    {character.slug === 'emilia' && (
                      <CategoryCard
                        title="Emilia review"
                        link="/nikke/guides/guide-emilia"
                        image={
                          <StaticImage
                            src="../images/nikke/categories/category_emi.webp"
                            alt="Emilia review"
                          />
                        }
                      />
                    )}
                  </Row>
                  <div className={`content-header rarity-${character.rarity}`}>
                    <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
                  </div>
                  {!character.isReviewPending && character.review ? (
                    <div className="section-analysis">
                      <Row className="pros-cons">
                        <Col sm="12" md="6">
                          <div className="box pros">
                            <h5>Pros</h5>
                            <hr />
                            <div className="raw list">
                              {renderRichText(character.pros, options)}
                            </div>
                          </div>
                        </Col>
                        <Col sm="12" md="6">
                          <div className="box cons">
                            <h5>Cons</h5>
                            <hr />
                            <div className="raw list">
                              {renderRichText(character.cons, options)}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div className="info-box">
                      <p>
                        <strong>
                          Pros and Cons for{' '}
                          <strong className={`rarity-${character.rarity}`}>
                            {character.name}
                          </strong>{' '}
                          aren't available yet.
                        </strong>{' '}
                        They will be added soon.
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <div className={`review raw`}>
                  {character.slug === 'ram' ||
                  character.slug === '2b' ||
                  character.slug === 'ludmilla-winter-owner' ||
                  character.slug === 'marciana' ||
                  character.slug === 'rapunzel-pure-grace' ||
                  character.slug === 'mica-snow-buddy' ||
                  character.slug === 'a2' ||
                  character.slug === 'tove' ||
                  character.slug === 'scarlet-black-shadow' ||
                  character.slug === 'misato-katsuragi' ||
                  character.slug === 'pascal' ||
                  character.slug === 'aqua-marine-helm' ||
                  character.slug === 'sparkling-summer-anis' ||
                  character.slug === 'noir' ||
                  character.slug === 'quiry' ||
                  character.slug === 'blanc' ||
                  character.slug === 'sakura-suzuhara' ||
                  character.slug === 'moran' ||
                  character.slug === 'viper-treasure' ||
                  character.slug === 'laplace-treasure' ||
                  character.slug === 'exia-treasure' ||
                  character.slug === 'diesel-treasure' ||
                  character.slug === 'frima-treasure' ||
                  character.slug === 'dorothy' ||
                  character.slug === 'nihilister' ||
                  character.slug === 'bay-goddess-mary' ||
                  character.slug === 'nihilister' ? (
                    <>
                      {character.review ? (
                        <>{renderRichText(character.review, options)}</>
                      ) : (
                        <div className="info-box">
                          <p>
                            <strong>
                              Review for{' '}
                              <strong className={`rarity-${character.rarity}`}>
                                {character.name}
                              </strong>{' '}
                              isn't available yet.
                            </strong>{' '}
                            It will be added soon.
                          </p>
                        </div>
                      )}
                      {!character.isReviewPending && character.review ? (
                        <div className="section-analysis">
                          <Row className="pros-cons">
                            <Col sm="12" md="6">
                              <div className="box pros">
                                <h5>Pros</h5>
                                <hr />
                                <div className="raw list">
                                  {renderRichText(character.pros, options)}
                                </div>
                              </div>
                            </Col>
                            <Col sm="12" md="6">
                              <div className="box cons">
                                <h5>Cons</h5>
                                <hr />
                                <div className="raw list">
                                  {renderRichText(character.cons, options)}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div className="info-box">
                          <p>
                            <strong>
                              Pros and Cons for{' '}
                              <strong className={`rarity-${character.rarity}`}>
                                {character.name}
                              </strong>{' '}
                              aren't available yet.
                            </strong>{' '}
                            They will be added soon.
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="info-box">
                        <p>
                          As it has been a long time since the initial analysis
                          of{' '}
                          <strong className={`rarity-${character.rarity}`}>
                            {character.name}
                          </strong>{' '}
                          and in light of the ever-evolving meta, the dedicated
                          review has been hidden.{' '}
                          {newRankings.Story_LD === 'SSS' ||
                          newRankings.Story_LD === 'SS' ||
                          newRankings.Story_LD === 'S' ||
                          newRankings.Story_LD === 'A' ||
                          newRankings.Story_HD === 'SSS' ||
                          newRankings.Story_HD === 'SS' ||
                          newRankings.Story_HD === 'S' ||
                          newRankings.Story_HD === 'A' ||
                          newRankings.Boss === 'SSS' ||
                          newRankings.Boss === 'SS' ||
                          newRankings.Boss === 'S' ||
                          newRankings.Boss === 'A' ? (
                            <>
                              However,{' '}
                              <strong className={`rarity-${character.rarity}`}>
                                {character.name}
                              </strong>{' '}
                              still{' '}
                              <strong>displays remarkable performance</strong>{' '}
                              in Story and/or Bosses. For more information,
                              visit our{' '}
                              <Link to="/nikke/guides/meta-teams">
                                Meta teams
                              </Link>{' '}
                              guide.
                            </>
                          ) : (
                            <>
                              Furthermore,{' '}
                              <strong className={`rarity-${character.rarity}`}>
                                {character.name}
                              </strong>{' '}
                              <strong>
                                has fallen out of fashion when it comes to PVE{' '}
                                {newRankings.PVP != 'SSS' &&
                                  newRankings.PVP != 'SS' &&
                                  newRankings.PVP != 'S' &&
                                  'and PVP '}
                                content
                              </strong>
                              . Consider using other higher-ranked units from
                              our tier list if possible.
                            </>
                          )}{' '}
                          {newRankings.PVP === 'SSS' ||
                          newRankings.PVP === 'SS' ||
                          newRankings.PVP == 'S' ? (
                            <>
                              The character <strong>is part of the meta</strong>{' '}
                              in PVP. For more information, visit our{' '}
                              <Link to="/nikke/guides/pvp-sp-arena">
                                PVP meta
                              </Link>{' '}
                              guide.
                            </>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong>
                  Review for{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  isn't available yet.
                </strong>{' '}
                It will be added soon.
              </p>
            </div>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          {!character.isReviewPending ? (
            <div>
              {character.slug === 'red-hood' && (
                <>
                  <p>
                    Red Hood is a special character whose Burst can be used in
                    every stage and we have added her in every category to
                    showcase how she performs when you use her as a specific
                    stage character. The ratings below showcase her strength
                    based on how you use her.
                  </p>
                  <h6>B1</h6>
                </>
              )}
              {character.slug === 'rapi-red-hood' && (
                <>
                  <p>
                    Red Hood is a special character whose Burst can be used in
                    B1 and B3 stages and we have added her in the two category
                    to showcase how she performs when you use her as a specific
                    stage character
                  </p>
                  <h6>B1</h6>
                </>
              )}
              <div className="detailed-ratings nikke">
                <RatingBox
                  game="new-nikke"
                  rating={newRankings.Story_LD}
                  ratingName="Story (low deficit)"
                />
                <RatingBox
                  game="new-nikke"
                  rating={newRankings.Story_HD}
                  ratingName="Story (high deficit)"
                />
                <RatingBox
                  game="new-nikke"
                  rating={newRankings.Boss}
                  ratingName="Bossing"
                />
                <RatingBox
                  game="new-nikke"
                  rating={newRankings.PVP}
                  ratingName="PVP"
                />
              </div>
              {character.slug === 'red-hood' && (
                <>
                  <h6>B2</h6>
                  <div className="detailed-ratings nikke">
                    <RatingBox
                      game="nikke"
                      rating="5"
                      ratingName="Story (low deficit)"
                    />
                    <RatingBox
                      game="nikke"
                      rating="5"
                      ratingName="Story (high deficit)"
                    />
                    <RatingBox game="nikke" rating="6" ratingName="Bossing" />
                    <RatingBox game="nikke" rating="8" ratingName="PVP" />
                  </div>
                  <h6>B3</h6>
                  <div className="detailed-ratings nikke">
                    <RatingBox
                      game="nikke"
                      rating="11"
                      ratingName="Story (low deficit)"
                    />
                    <RatingBox
                      game="nikke"
                      rating="11"
                      ratingName="Story (high deficit)"
                    />
                    <RatingBox game="nikke" rating="11" ratingName="Bossing" />
                    <RatingBox game="nikke" rating="10" ratingName="PVP" />
                  </div>
                </>
              )}
              {character.slug === 'rapi-red-hood' && (
                <>
                  <h6>B3</h6>
                  <div className="detailed-ratings nikke">
                    <RatingBox
                      game="nikke"
                      rating="11"
                      ratingName="Story (low deficit)"
                    />
                    <RatingBox
                      game="nikke"
                      rating="11"
                      ratingName="Story (high deficit)"
                    />
                    <RatingBox game="nikke" rating="11" ratingName="Bossing" />
                    <RatingBox game="nikke" rating="8" ratingName="PVP" />
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>ratings aren't available yet.</strong> They will be
                added soon!
              </p>
            </div>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header rarity-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Investment Tips
            </p>
          </div>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skill Investment
          </div>
          <Accordion className="tier-list-accordion skills">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Skill Investment explained</Accordion.Header>
              <Accordion.Body>
                <p>
                  Here's the explanation for all the numbers and colors used in
                  the guide:
                </p>
                <ul>
                  <li>
                    <strong>10/5/7</strong> means upgrading Skill 1 to lvl 10,
                    Skill 2 to lvl 5, and Burst Skill to lvl 7,
                  </li>
                  <li>
                    <strong className="start">Yellow</strong> means the
                    immediate investment when u get the unit. There are 3
                    reasons for this:
                  </li>
                  <ul>
                    <li>
                      If the unit is Meta or High Priority, it will have a
                      starting investment of more than 1/1/1 (such as 4/4/4)
                      depending on what the unit provides.
                    </li>
                    <li>
                      If the unit is Medium or Low Priority, 1/1/1 will be
                      mentioned, meaning no immediate investment is required,
                      and you can invest in this character if you need to use it
                      according to the priority listed.
                    </li>
                    <li>
                      If we deem that a unit does not have usable skills to be
                      meta for any gamemode, no investment will be mentioned.
                    </li>
                  </ul>
                  <li>
                    <strong className="min">Blue</strong> means minimum
                    investment to take advantage of the Nikke's skills. There
                    are 2 reasons for this:
                  </li>
                  <ul>
                    <li>
                      Sometimes, minimum investments are breakpoints such as for
                      Noir or SAnis.
                    </li>
                    <li>
                      Sometimes, minimum investments are just the ideal way to
                      progress to upgrade that unit, before moving on to
                      Recommended and High.
                    </li>
                  </ul>
                  <li>
                    <strong className="rec">Red</strong> means recommended
                    investment for F2P players,
                  </li>
                  <li>
                    <strong className="high">Purple</strong> means maximum
                    recommended investment, in order to get the best out of the
                    unit. You should only try to reach these investments if you
                    have a surplus of Skill Books leftover.
                  </li>
                </ul>
                <p>More info:</p>
                <ul>
                  <li>
                    The skill investment list below features a lot of Level 4
                    and 7 because they are easier to understand. Any higher
                    levels will cost a new type of resource.
                  </li>
                  <li>
                    However, you may choose to upgrade to Level 5 if you need a
                    stronger effect instead of 4, as the increase in Blue Manual
                    cost from Level 4 to 5 is still low. The leap only starts
                    from Level 6 onward.
                  </li>
                  <li>
                    There is also a leap from Level 3 to 4, so if you want to
                    save a tiny bit of Blue Manuals, then leave at 3 instead of
                    4.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {/* <div className="nikke-skills-tips">
            <div className="nikke-skills-row header">
              <div className="column character">&nbsp;</div>
              <div className="column suggestions pve">PVE Investment</div>
              <div className="column suggestions pvp">PVP Investment</div>
              <div className="column priority">Priority</div>
            </div>
            <div className="nikke-skills-row data">
              <NikkeSkill char={character.slug} />
            </div>
          </div> */}
          {character.isReviewPending ? (
            <>
              <div className="info-box">
                <p>
                  <strong>
                    Skill investment tips for{' '}
                    <strong className={`rarity-${character.rarity}`}>
                      {character.name}
                    </strong>{' '}
                    aren't available yet.
                  </strong>{' '}
                  They will be added soon.
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char={character.slug} />
                </div>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gear Investment
          </div>
          <Accordion className="tier-list-accordion skills">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Gear Investment explained</Accordion.Header>
              <Accordion.Body>
                <p>
                  This Overload (OL) Recommendations and Priority guide unravels
                  in detail everything you need about a character in terms of
                  priority, overload targets (effects), and order of
                  acquisition, alongside some notes to explain our thinking
                  process.
                </p>
                <p>
                  For OL targets and order of acquisition, we decided to divide
                  the target effects into several categories.
                </p>
                <ul>
                  <li>
                    <strong>Essential</strong>: With all gear combined, your
                    unit must have the roll(s) prescribed as a minimum
                    requirement. Example: Charge Speed on Alice. You cannot
                    compromise on these rolls.
                  </li>
                  <li>
                    <strong>Ideal</strong>: These rolls will improve your unit's
                    performance significantly. Aim for 2 lines of
                    Essential/Ideal per gear for maximum performance and the
                    best Return on Investment (ROI). If you get 3, you are
                    lucky. You can choose to only have 1 as well to save
                    materials. Example: Elemental Damage on Scarlet.
                  </li>
                  <li>
                    <strong>Passable:</strong> Low-priority line that has either
                    limited value or the impact is not as strong as other rolls.
                    Should this show up as a bonus together with a better line,
                    you can choose to keep them. Example: Charge Speed on Red
                    Hood.
                  </li>
                </ul>
                <p>
                  Overload effects are ordered by importance from Left to Right,
                  which means anything on the left provides more value than the
                  right. Effects that belong to the same group generate too
                  similar an impact to make a difference (and are sometimes
                  interchangeable). Try to follow this order as faithfully as
                  possible for maximum mileage.
                </p>
                <p>
                  <strong>Additional tips:</strong>
                </p>
                <ul>
                  <li>
                    Priority (PvE/PvP) isn't necessarily lower than Priority
                    (Universal), which has no tag/label. It just indicates that
                    this unit performs only in select types of content.
                  </li>
                  <li>
                    If a suggested effect has the number ×2 next to it, that
                    means you should get the effect on at least 2 gear. The same
                    idea applies to other numbers.
                  </li>
                  <li>
                    Focus on obtaining Essential(s) then you can start worrying
                    about optimizing your rolls. Units require these to
                    function, and the impact is too massive to ignore.
                  </li>
                  <li>
                    If a unit has no Essential(s), that means their performance
                    is not that dependent on OL, but they can still benefit from
                    good effects.
                  </li>
                  <li>
                    Remember that this is just a recommendation list, and
                    attaining perfection is impossible. You will have to make
                    some compromise, pick whichever rolls you desire the most,
                    and conserve as much material as possible.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {character.isReviewPending ? (
            <div className="info-box">
              <p>
                <strong>
                  Gear investment tips for{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  aren't available yet.
                </strong>{' '}
                They will be added soon.
              </p>
            </div>
          ) : (
            <>
              <div className="nikke-skills">
                <div className="nikke-skills-row over header">
                  <div className="column character">Character</div>
                  <div className="column suggestions essential">Essential</div>
                  <div className="column suggestions ideal">Ideal</div>
                  <div className="column suggestions passable">Passable</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-row">
                  <div className="nikke-skills-row over data">
                    <div className="column character">
                      <NikkeCharacter
                        slug={character.slug}
                        mode="icon"
                        showLabel
                        enablePopover
                        onlyName
                      />
                    </div>
                    <div className="column suggestions pve">
                      <p className="on-mobile">Essential</p>
                      {character.olInfo.Essential_1 ? (
                        <p>
                          <strong>{character.olInfo.Essential_1}</strong>
                        </p>
                      ) : (
                        <p>-</p>
                      )}
                      {character.olInfo.Essential_2 && (
                        <p>
                          <strong>{character.olInfo.Essential_2}</strong>
                        </p>
                      )}
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Ideal</p>
                      {character.olInfo.Ideal_1 ? (
                        <p>
                          <strong>{character.olInfo.Ideal_1}</strong>
                        </p>
                      ) : (
                        <p>-</p>
                      )}
                      {character.olInfo.Ideal_2 && (
                        <p>
                          <strong>{character.olInfo.Ideal_2}</strong>
                        </p>
                      )}
                      {character.olInfo.Ideal_3 && (
                        <p>
                          <strong>{character.olInfo.Ideal_3}</strong>
                        </p>
                      )}
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Passable</p>
                      {character.olInfo.Passable ? (
                        <p>
                          <strong>{character.olInfo.Passable}</strong>
                        </p>
                      ) : (
                        <p>-</p>
                      )}
                    </div>
                    <div className="column priority">
                      <p className="on-mobile">Priority</p>
                      {character.olInfo.Priority ? (
                        <>
                          {character.olInfo.Priority >= 11 && (
                            <p className={`prio prio-11`}>Meta</p>
                          )}
                          {character.olInfo.Priority >= 10 &&
                            character.olInfo.Priority < 11 && (
                              <p className={`prio prio-10`}>High</p>
                            )}
                          {character.olInfo.Priority === 9 && (
                            <p className={`prio prio-10`}>High (PVE)</p>
                          )}
                          {character.olInfo.Priority === 8 && (
                            <p className={`prio prio-10`}>High (PVP)</p>
                          )}
                          {character.olInfo.Priority === 7 && (
                            <p className={`prio prio-5`}>Medium</p>
                          )}
                          {character.olInfo.Priority === 6 && (
                            <p className={`prio prio-5`}>Medium (PVE)</p>
                          )}
                          {character.olInfo.Priority === 5 && (
                            <p className={`prio prio-5`}>Medium (PVP)</p>
                          )}
                          {character.olInfo.Priority === 4 && (
                            <p className={`prio prio-3`}>Low</p>
                          )}
                          {character.olInfo.Priority === 3 && (
                            <p className={`prio prio-3`}>Low (PVE)</p>
                          )}
                          {character.olInfo.Priority === 2 && (
                            <p className={`prio prio-3`}>Low (PVP)</p>
                          )}
                          {character.olInfo.Priority === 1 && (
                            <p className={`prio prio-1`}>Very Low</p>
                          )}
                          {character.olInfo.Priority === 0 && (
                            <p className="prio">-</p>
                          )}
                        </>
                      ) : (
                        <>-</>
                      )}
                    </div>
                  </div>
                  <div className={`explanation`}>
                    {character.olInfo.Comments ? (
                      <p>{character.olInfo.Comments}</p>
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Cube Investment
          </div>
          {character.isReviewPending ? (
            <div className="info-box">
              <p>
                <strong>
                  Cube investment tips for{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  aren't available yet.
                </strong>{' '}
                They will be added soon.
              </p>
            </div>
          ) : (
            <>
              {character.harmonyCubes ? (
                <>
                  <div className="cube-investment">
                    <div className="cube-table">
                      <div className="column">
                        <h5 className="pre">Pre-overload</h5>
                        <ul>
                          <li>
                            <NikkeCubeTable
                              cube={character.harmonyCubes.pre_1}
                            />
                          </li>
                          {character.harmonyCubes.pre_2 && (
                            <li>
                              <NikkeCubeTable
                                cube={character.harmonyCubes.pre_2}
                              />
                            </li>
                          )}
                          {character.harmonyCubes.pre_3 && (
                            <li>
                              <NikkeCubeTable
                                cube={character.harmonyCubes.pre_3}
                              />
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="column">
                        <h5 className="post">Post-overload</h5>
                        <ul>
                          <li>
                            <NikkeCubeTable
                              cube={character.harmonyCubes.post_1}
                            />
                          </li>
                          {character.harmonyCubes.post_2 && (
                            <li>
                              <NikkeCubeTable
                                cube={character.harmonyCubes.post_2}
                              />
                            </li>
                          )}
                          {character.harmonyCubes.post_3 && (
                            <li>
                              <NikkeCubeTable
                                cube={character.harmonyCubes.post_3}
                              />
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="raw list">
                      {character.harmonyCubesInfo && (
                        <>
                          {renderRichText(character.harmonyCubesInfo, options)}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="info-box">
                  <p>
                    <strong>
                      Cube investment tips for{' '}
                      <strong className={`rarity-${character.rarity}`}>
                        {character.name}
                      </strong>{' '}
                      aren't available yet.
                    </strong>{' '}
                    They will be added soon.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default NikkeCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | NIKKE | Prydwen Institute'}
      description={
        character.backstory
          ? character.backstory.backstory
          : 'One of the characters in Goddess of Victory: NIKKE.'
      }
      image={character.smallImage}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulNikkeCharacter(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...NikkeCharacterFieldsFragment
      }
    }
  }
`;
